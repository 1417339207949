import { MODULES_CODE } from '@carwash-project/modules/data-access/common';
import { AccessModel } from '@carwash-project/modules/data-access/storage';
import { MainMenuModel } from '@carwash-project/modules/ui';

export const MAIN_MENU: MainMenuModel[] = [
  {
    code: MODULES_CODE.ADMIN_001,
    visible: true,
    level: 1,
    label: 'Dashboard',
    icon: 'fa-solid fa-house fa-xl',
    selected: true,
    router: '/main/dashboard',
  },
  {
    code: MODULES_CODE.ADMIN_001,
    visible: true,
    level: 1,
    label: 'Dashboard-new',
    icon: 'fa-solid fa-house fa-xl',
    selected: true,
    router: '/main/dashboard/stats',
  },
  {
    code: MODULES_CODE.ADMIN_002,
    visible: true,
    level: 1,
    label: 'Reportería',
    icon: 'fa-solid fa-chart-simple fa-xl',
    router: '/main/reports',
  },
  {
    code: MODULES_CODE.ADMIN_003,
    visible: true,
    level: 1,
    label: 'Inventario',
    icon: 'fa-solid fa-table-cells-large fa-xl',
    router: '/main/inventory',
  },
  {
    code: MODULES_CODE.ADMIN_004,
    visible: true,
    level: 1,
    label: 'Empleados',
    icon: 'fa-solid fa-user-check fa-xl',
    router: '/main/employee',
  },
  {
    code: MODULES_CODE.ADMIN_005,
    visible: true,
    level: 1,
    label: 'Anuncios',
    icon: 'fa-solid fa-bell fa-xl',
    router: '/main/adverts',
  },
  {
    code: MODULES_CODE.ADMIN_006,
    visible: true,
    level: 1,
    label: 'Clientes',
    icon: 'fa-solid fa-user-group fa-xl',
    router: '/main/customers',
  },
  {
    code: MODULES_CODE.ADMIN_007,
    visible: true,
    level: 1,
    label: 'Gift cards',
    icon: 'fa-solid fa-gift fa-xl',
    router: '/main/gift-cards',
  },
  // {
  //   code: MODULES_CODE.ADMIN_007,
  //   visible: true,
  //   level: 1,
  //   label: 'Administración',
  //   icon: 'fa-solid fa-gears fa-xl',
  //   children: [
  //     {
  //       level: 2,
  //       label: 'Promociones',
  //       icon: 'fa-solid fa-bullhorn fa-xl',
  //       router: '/main/promotions',
  //     },
  //     {
  //       level: 2,
  //       label: 'Gift cards',
  //       icon: 'fa-solid fa-gift fa-xl',
  //       router: '/main/gift-cards',
  //     },
  //   ],
  // },
  {
    code: MODULES_CODE.ADMIN_008,
    visible: true,
    level: 1,
    label: 'Encuestas',
    icon: 'fa-solid fa-comment-dots fa-xl',
    router: '/main/surveys',
  },
];

export const MAIN_MENU_USER: MainMenuModel[] = [
  {
    code: MODULES_CODE.CLIENT_001,
    visible: true,
    level: 1,
    label: 'Dashboard',
    icon: 'fa-solid fa-star fa-xl',
    selected: true,
    router: '/main/dashboard-user',
  },
];

export const menuFilterFn = (menu: MainMenuModel[], access: AccessModel[]) => {
  return menu.map((item) => ({
    ...item,
    visible:
      access.length && item.code
        ? access.find((i) => i.name == item.code)?.canAccess
        : false,
  }));
};
